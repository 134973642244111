import React from "react";

const NotFound = () => {
  return (
    <div>
      <h2 className="text-4xl text-primary text-center mt-[100px]">
        404. Not Found!
      </h2>
    </div>
  );
};

export default NotFound;
